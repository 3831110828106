import CountUp from "react-countup";

const Counter = () => {
    return ( 
        <div className="stat flex flex-row justify-start items-start gap-2 lg:gap-10 mt-10">
              {/* <div className="justify-start items-center gap-12 inline-flex"> */}
                <div className="flex-col justify-start items-start">
                  <p className="text-gray-800 text-[40px] lg:text-[64px] font-normal font-['Lora']">
                    <CountUp start={0} end={1000} duration={10} decimals={0} />+
                  </p>
                  <p className="opacity-50 text-gray-800 text-2xl font-normal font-['Poppins']">
                    Workers
                  </p>
                </div>
              
                <div className="divider divider-horizontal"></div>
                <div className="flex-col justify-start items-start">
                <p className="text-gray-800 text-[40px] lg:text-[64px] font-normal font-['Lora']">
                    <CountUp start={0} end={150} duration={10} decimals={0} />+
                  </p>
                  <p className="opacity-50 text-gray-800 text-2xl font-normal font-['Poppins']">
                    Built Houses
                  </p>
                </div>
                {/* <div className="divider divider-horizontal"></div> */}
              {/* </div> */}
            </div>
     );
}
 
export default Counter;