import Navbar2 from "../components/Navbar2.js";
import vrf_bg from "../images/optimized/vrf/vrf-bg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import icon1 from "../images/engineer.png";
import icon2 from "../images/ruler.png";
import icon3 from "../images/measuring-tool.png";
import vrf_video from "../video/vrf_video.mp4";
import { Fade, Slide, Zoom, Rotate } from "react-reveal";
import VRFSecondSection from "./components/VRFSecondSection.js";
import Footer from "../components/Footer.js";

const VRF = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${vrf_bg})`,
        }}
        className="bg-cover bg-center bg-no-repeat pb-40"
      >
        

        {/*Top Section*/}
        <div className="pt-[90px] relative mx-auto w-11/12 flex flex-col lg:flex-row gap-5 justify-start items-center lg:items-start">
          {/*left*/}
          <div className="lg:mt-10 w-full lg:w-1/2 flex flex-col justify-center items-center md:justify-start md:items-start">
            <Slide left duration={1400}>
            <h1 className="text-left font-Lora text-6xl text-white">
              Seamlessly Supplied and Installed:<br></br>Your VRF Partner.
            </h1>
            </Slide>
            <button className="mt-5 w-60 font-Poppins h-[60px] bg-gradient-to-b from-sky-700 to-blue-500 shadow text-white hover:scale-110 transition-transform duration-300">
              Get in Touch
              <FontAwesomeIcon
                icon={faArrowRight}
                className="pl-2 text-white"
              />
            </button>
          </div>

          {/*right*/}
          <Slide bottom duration={1400}>
          <div className="w-full lg:w-1/2 absolute vrf-right lg:ml-[470px] flex flex-col justify-center items-center mt-[500px] md:mt-[300px] lg:mt-[180px] right mb-10">
            <div className="w-[100%] md:w-[420px] mx-auto h-[4px] bg-[#001ED3] mb-5"></div>
            <div
              style={{
                boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
              }}
              className="w-[100%] md:w-[420px] mx-auto h-[480px] md:h-[418px] px-10 bg-white flex flex-col gap-5 text-left justify-center items-center"
            >
              <div className="flex flex-row gap-5 justify-center items-start md:items-center">
                <div>
                  <img src={icon1} className="" />
                </div>
                <div>
                  <p className="font-semibold font-Kanit text-[18px]">
                    Design Versatility
                  </p>
                  <p className="text-[#41444B] text-[17px]">
                    Using the best technologies of the world at our construction
                    site.
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-5 justify-center items-start md:items-center">
                <div>
                  <img src={icon2} className="w-12" />
                </div>
                <div className="pl-1">
                  <p className="font-semibold font-Kanit text-[18px]">
                    Scalability{" "}
                  </p>
                  <p className="text-[#41444B] text-[17px]">
                    Strictly adherence to safety and quality of our services.
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-5 justify-center items-start md:items-center">
                <div>
                  <img src={icon3} className="w-20" />
                </div>
                <div>
                  <p className="font-semibold font-Kanit text-[18px]">
                    Reliability and Durability{" "}
                  </p>
                  <p className="text-[#41444B] text-[17px]">
                    Sustain client priorities of our clients and be functionally
                    state-of-art with integrity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </Slide>
        </div>
      </div>
      {/*VRFSecondSection*/}
      <VRFSecondSection />
      <video
            className="w-1/2 mb-10 mx-auto flex justify-center items-center"
            src={vrf_video}
            autoPlay
            loop
            muted
            playsInline
          ></video>
      <Footer />
    </div>
  );
};
export default VRF;
