import contact_bg from "../images/optimized/contact-bg.jpg";
import Navbar2 from "../components/Navbar2.js";
import ContactForm from "./components/ContactForm.js";
import iconcall from "../images/iconcall.png";
import iconmail from "../images/iconemail.png";
import Footer from "../components/Footer.js";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${contact_bg})`,
        }}
        className="bg-cover bg-center bg-no-repeat pb-40"
      >
        
        <div className="pt-[120px] flex flex-col justify-start items-start mx-auto ml-[95px]">
          <p className="text-left text-white text-[72px] font-Lora font-bold">
            Contact
          </p>
        </div>
      </div>

      <ContactForm />

      <div className="mt-10 mb-5 w-11/12 mx-auto flex flex-col lg:flex-row justify-center text-left items-center gap-5">
        <div className="w-full lg:w-1/3 py-[80px] shadow-lg border-l-4 pl-10 pr-10 border-[#0454A0]">
          <p className="uppercase font-Poppins text-[#41444B] font-light text-[14px]">
            office address
          </p>
          <Link to="https://maps.app.goo.gl/dfE8L4amXERmUycN6" target="_blank">
          <p className="font-Poppins font-normal text-[20px] hover:text-[#0454A0]">
            House-77/B , Block- F, Road-3, Banani, 1213 Dhaka, Bangladesh.
          </p>
          </Link>
        </div>
        <div className="flex flex-col md:flex-row gap-10 justify-start lg:justify-center items-start lg:items-center">
          <div className="flex flex-row justify-center items-center gap-1">
            <div>
              <img src={iconcall} className="mr-2" />
            </div>
            <div>
              <p className="uppercase font-Poppins text-[14px] font-light">
                company phones
              </p>
              <Link to="tel:+8801988818050">
                <p className="font-Poppins text-[22px] hover:text-[#0454A0]">+88-01988818050</p>
              </Link>
              <Link to="tel:+8801859666594">
                <p className="font-Poppins text-[22px] hover:text-[#0454A0]">+88-01859666594</p>
              </Link>
            </div>
          </div>

          <div className="flex flex-row justify-center items-center gap-1">
            <Link>
            <div>
              <img src={iconmail} className="mr-2" />
            </div>
            </Link>
            <div>
              <p className="uppercase font-Poppins text-[14px] font-light">
                bts emails
              </p>
              <Link to="mailto:btsbd@gmail.com">
                <p className="font-Poppins text-[22px] hover:text-[#0454A0]">btsbd@gmail.com</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
