import { useState } from "react";
import { Fade, Slide, Zoom, Rotate } from "react-reveal";
import AhiIndia from "../../images/optimized/logos/removedbg/1.png";
import Ge from "../../images/optimized/logos/removedbg/5.png";
import bond from "../../images/optimized/logos/removedbg/3.png";
import durlum from "../../images/optimized/logos/removedbg/4.png";
import alpolic from "../../images/optimized/logos/removedbg/2.png";
import auth_bg_2 from "../../images/optimized/auth-bg.jpg";
import {Link} from "react-router-dom";

const AsahiIndia = () => {
  return (
    <div className="mx-auto pb-10 w-11/12">
      <div className="flex flex-col md:flex-row gap-5 justify-center items-start">
        <div className="left w-full lg:w-1/4">
          <img src={AhiIndia} />
        </div>
        <Slide right duration={1400}>
        <div className="right w-full lg:w-1/2 text-left text-[20px] font-Poppins">
          <p className="pb-5">
            Asahi India Glass Ltd (AIS) is India’s leading integrated glass and
            window solutions company and a dominant player both in the
            automotive and the building & construction segments. Our product
            solutions spanning the entire breadth of automotive, building &
            construction, and consumer glass, are designed to deliver aesthetics
            and functional benefits.
          </p>
          <ol style={{ listStyleType: "none", paddingLeft: "1.5em" }}>
            <li style={{ listStyle: "disc" }}>Ais designer glass</li>
            <li style={{ listStyle: "disc" }}>Architectural glass</li>
            <li style={{ listStyle: "disc" }}>Bullet resistant glass</li>
            <li style={{ listStyle: "disc" }}>Switchable Smart glass</li>
            <li style={{ listStyle: "disc" }}>Automobile laminated glass</li>
            <li style={{ listStyle: "disc" }}>Integrated Glass Solutions</li>
            <li style={{ listStyle: "disc" }}>Ais déco</li>
            <li style={{ listStyle: "disc" }}>Ais krystal</li>
            <li style={{ listStyle: "disc" }}>Designer glass door</li>
          </ol>
        </div>
        </Slide>
      </div>
      <div className="flex justify-end mx-auto w-11/12">
        <Link to="https://www.aisglass.com/" target="_blank">
        <button className="font-Poppins mt-5 px-[50px] py-[10px] bg-gradient-to-b from-sky-700 to-blue-500 shadow text-white">
          View More
        </button>
        </Link>
      </div>
    </div>
  );
};

const GESilicons = () => {
  return (
    <div className="mx-auto pb-10 w-11/12">
      <div className="flex flex-col md:flex-row gap-5 justify-center items-start">
        <div className="left w-full lg:w-1/4">
          <img src={Ge} />
        </div>
        <Slide right duration={1400}>
        <div className="right w-full lg:w-1/2 text-left text-[20px] font-Poppins">
          <p className="font-bold">Window & Door</p>
          <p className="pb-5">
            Our 100% Silicone Window & Door sealants create weatherproof
            exterior seals that can withstand harsh weather conditions, changing
            temperatures, UV rays and more.
          </p>

          <p className="font-bold">Kitchen & Bath</p>
          <p className="pb-5">
            Our 100% Silicone Kitchen & Bath sealants offer complete waterproof
            protection and mold-resistance to create durable, easy-to-clean
            seals in areas exposed to water and humidity.
          </p>

          <p className="font-bold">Specialty</p>
          <p className="">
            For sealing materials like metal, concrete, PVC or other non-porous
            surfaces, choose a 100% silicone specialty sealant that’s formulated
            for your specific sealing project.
          </p>
        </div>
        </Slide>
      </div>
      <div className="flex justify-end mx-auto w-11/12">
        <Link to="https://siliconeforbuilding.com/" target="_blank">
        <button className="font-Poppins mt-5 px-[50px] py-[10px] bg-gradient-to-b from-sky-700 to-blue-500 shadow text-white">
          View More
        </button>
        </Link>
      </div>
    </div>
  );
};

const Bond = () => {
  return (
    <div className="mx-auto pb-10 w-11/12">
      <div className="flex flex-col md:flex-row gap-5 justify-center items-start">
        <div className="left w-full lg:w-1/4">
          <img src={bond} />
        </div>
        <Slide right duration={1400}>
        <div className="right w-full lg:w-1/2 text-left text-[20px] font-Poppins">
          <p>
            Bond Sealant is a brain child of Cosmopolitan Group USA. After
            adding a name in the industry with several building material
            products all over the world, Cosmopolitan LLC have launched a series
            of Bond Sealants currently one of the Most Emerging Sealant Brand in
            the market distributed all over the world. All of our products are
            developed and tested in USA. Our manufacturing plants are in USA &
            CHINA. Our goal is to provide re-engineered quality sealants which
            would keep our BOND stronger then ever!
          </p>
        </div>
        </Slide>
      </div>
      <div className="flex justify-end mx-auto w-11/12">
        <Link to="https://bondsealant.com" target="_blank">
        <button className="font-Poppins mt-5 px-[50px] py-[10px] bg-gradient-to-b from-sky-700 to-blue-500 shadow text-white">
          View More
        </button>
        </Link>
      </div>
    </div>
  );
};

const Durlum = () => {
  return (
    <div className="mx-auto pb-10 w-11/12">
      <div className="flex flex-col md:flex-row gap-5 justify-center items-start">
        <div className="left w-full lg:w-1/4">
          <img src={durlum} />
        </div>
        <Slide right duration={1400}>
        <div className="right w-full lg:w-1/2 text-left text-[20px] font-Poppins">
          <p>
            durlum develops and produces metal ceilings, lighting and daylight
            lighting systems for modern architecture. Metal ceilings and
            luminaires, integrated ceiling-lighting solutions, daylight systems
            and wall claddings are all suitable for a versatile range of
            architectural applications. From airports and railway stations to
            shopping malls, office buildings and educational establishments,
            health centres, cultural buildings, leisure facilities or outdoor
            architecture – durlum can provide an appropriate and individual
            solution for any application
          </p>
        </div>
        </Slide>
      </div>
      <div className="flex justify-end mx-auto w-11/12">
        <Link to="https://www.durlum.com/en/" target="_blank">
        <button className="font-Poppins mt-5 px-[50px] py-[10px] bg-gradient-to-b from-sky-700 to-blue-500 shadow text-white">
          View More
        </button>
        </Link>
      </div>
    </div>
  );
};

const Alpolic = () => {
  return (
    <div className="mx-auto pb-10 w-11/12">
      <div className="flex flex-col md:flex-row gap-5 justify-center items-start">
        <div className="left w-full lg:w-1/4">
          <img src={alpolic} />
        </div>
        <Slide right duration={1400}>
        <div className="right w-full lg:w-1/2 text-left text-[20px] font-Poppins">
          <p className="pb-5">
            There are many manufacturers of metal composite materials on the
            market, but there is only one ALPOLIC. Only one who stands solidly
            behind our products with industry-leading warranties that can help
            protect your investment for 10, 20, or even 30 years.
          </p>
          <ol style={{ listStyleType: "none", paddingLeft: "1.5em" }}>
            <li style={{ listStyle: "disc" }}>Strongest Warranty</li>
            <li style={{ listStyle: "disc" }}>Primed Both Sides of Skin</li>
            <li style={{ listStyle: "disc" }}>AAMA 2605 FEVE Paint</li>
            <li style={{ listStyle: "disc" }}>Graffiti Resistance</li>
            <li style={{ listStyle: "disc" }}>
              Custom Production: 1,000 sq. ft. minimum
            </li>
            <li style={{ listStyle: "disc" }}>sizes: 2mm, 3mm, 4mm, 6mm</li>
            <li style={{ listStyle: "disc" }}>
              Widths: 40, 48, 50, 51, 60, 62
            </li>
            <li style={{ listStyle: "disc" }}>Full Technical Service Staff</li>
            <li style={{ listStyle: "disc" }}>Squared Sheet</li>
            <li style={{ listStyle: "disc" }}>Good Side Up or Down</li>
          </ol>
        </div>
        </Slide>
      </div>
      <div className="flex justify-end mx-auto w-11/12">
        <Link to="https://www.alpolic-americas.com/" target="_blank">
        <button className="font-Poppins mt-5 px-[50px] py-[10px] bg-gradient-to-b from-sky-700 to-blue-500 shadow text-white">
          View More
        </button>
        </Link>
      </div>
    </div>
  );
};

const AuthThirdSection = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${auth_bg_2})`,
      }}
      className="bg-cover mt-10 lg:mt-0 bg-no-repeat pb-10"
    >
      <div className="flex flex-col justify-center items-center lg:pt-20">
      <div className="divider divider-neutral mt-10"></div>
        <div className="w-11/12 mx-auto flex flex-col pb-3 md:flex-row mt-5 italic font-Poppins text-[25px] lg:text-[40px] gap-5 justify-center items-center">
          <a
            className={
              activeComponent === "AsahiIndia" || activeComponent === null
                ? "active"
                : ""
            }
            onClick={() => handleButtonClick("AsahiIndia")}
          >
            Asahi India Glass Ltd
          </a>
          <div className="w-2 h-1 bg-black"></div>
          <a
            className={activeComponent === "GESilicons" ? "active" : ""}
            onClick={() => handleButtonClick("GESilicons")}
          >
            GE Silicons
          </a>
          <div className="w-2 h-1 bg-black"></div>
          <a
            className={activeComponent === "Bond" ? "active" : ""}
            onClick={() => handleButtonClick("Bond")}
          >
            BOND Sealant
          </a>
          <div className="w-2 h-1 bg-black"></div>
          <a
            className={activeComponent === "Durlum" ? "active" : ""}
            onClick={() => handleButtonClick("Durlum")}
          >
            durlum
          </a>
          <div className="w-2 h-1 bg-black"></div>
          <a
            className={activeComponent === "Alpolic" ? "active" : ""}
            onClick={() => handleButtonClick("Alpolic")}
          >
            Alpolic
          </a>
        </div>
        <div className="divider divider-neutral"></div>
      </div>
      <div>
        {activeComponent === null && <AsahiIndia />}
        {activeComponent === "AsahiIndia" && <AsahiIndia />}
        {activeComponent === "GESilicons" && <GESilicons />}
        {activeComponent === "Bond" && <Bond />}
        {activeComponent === "Durlum" && <Durlum />}
        {activeComponent === "Alpolic" && <Alpolic />}
      </div>
    </div>
  );
};

export default AuthThirdSection;
