import Navbar2 from "../components/Navbar2.js";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Fade, Slide, Zoom, Rotate } from "react-reveal";

import logo1 from "../images/optimized/logos/removedbg/1.png";
import logo2 from "../images/optimized/logos/removedbg/2.png";
import logo3 from "../images/optimized/logos/removedbg/3.png";
import logo4 from "../images/optimized/logos/removedbg/4.png";
import logo5 from "../images/optimized/logos/removedbg/5.png";
import auth_bg from "../images/optimized/authorised-agent-bg.jpg";
import AuthThirdSection from "./components/AuthThirdSection";
import "./auth.css";

const AuthorisedAgents = () => {
  const three = [
    {
      title: "Brand Advocacy",
      description: "Inspire and support authorized agents in becoming enthusiastic advocates for our premium brands, including Alpolic, AIS glass, GE Silicones, and BOND USA."
    },
    {
      title: "Strategic Partnerships",
      description: "Cultivate and strengthen collaborative partnerships with authorized agents, fostering a mutually beneficial relationship."
    },
    {
      title: "Quality Assurance",
      description: "Maintain a high standard of quality assurance, working closely with authorized agents"
    }
  ] 
  return (
    <div className="">
      <div
      style={{
        backgroundImage: `url(${auth_bg})`,
        
      }}
       className="bg-cover bg-center bg-no-repeat pb-40 md:pb-60"
      >
      

      {/*Hero*/}
      <div className="min-h-[100vh] pt-[90px] md:min-h-[40vh] grid grid-cols-1 md:grid-cols-2 justify-items-center mx-auto w-11/12 gap-5">
        <div className="left lg:mt-10 flex flex-col">
          <Slide left duration={1400}>
          <h1 className="text-left font-Lora text-6xl text-white">
            Empowering Progress:<br></br>27 Years of Authorised Excellence
          </h1>
          </Slide>
          <button className="mt-5 w-60 font-Poppins h-[60px] bg-gradient-to-b from-sky-700 to-blue-500 shadow text-white hover:scale-110 transition-transform duration-300">
              Get in Touch
              <FontAwesomeIcon
                icon={faArrowRight}
                className="pl-2 text-white"
              />
            </button>
        </div>
        
        <div className="right mt-5 md:mt-20 stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <img src={logo2} />
            </div>
            <div className="face2">
              <img src={logo3} />
            </div>
            <div className="face3">
              <img src={logo1} />
            </div>
            <div className="face4">
              <img src={logo4} />
            </div>
            <div className="face5">
              <img src={logo5} />
            </div>
            <div className="face6">
              <img src={logo1} />
            </div>
          </div>
        </div>
        
      </div>
      </div>

      {/*Second Section*/}
      <Slide bottom duration={1400}>
      <div className="lg:absolute three mx-auto lg:ml-[60px] mt-10 lg:mt-[-120px] w-11/12 flex flex-row flex-wrap lg:flex-nowrap justify-center items-start lg:justify-start lg:items-start gap-3">
        {three.map((el,i) => {
          return(
            <div className="p-8 w-[356px] h-[220px] lg:h-[215px] text-left bg-gradient-to-b from-white to-[#E3EFFF]">
              <p className="font-bold font-Playfair text-[24px] pb-2">{el.title}</p>
              <p className="text-[16px] font-Poppins">{el.description}</p>
            </div>
          );
        })}
      </div>
      </Slide>

      {/*Third Section*/}
      <AuthThirdSection />
      <Footer />
    </div>
  );
};

export default AuthorisedAgents;
