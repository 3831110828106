import { Fade, Slide, Zoom, Rotate } from "react-reveal";
import comma from "../../images/designStudio/comma.png";
import border from "../../images/designStudio/sideborder.png";
import ds_1 from "../../images/optimized/designStudio/ds-1.jpg";


const DesignStudioSecond = () => {
  return (
    <div className="mt-10 w-11/12 mx-auto flex flex-col lg:flex-row gap-5 justify-center items-center">
      {/*LEFT*/}
      <Slide left duration={1400}>
      <div className="left w-full lg:w-1/2 text-left font-Poppins flex flex-col justify-center items-start">
        <p className="text-[#0454A0] text-[14px] pb-5 font-medium">ABOUT US</p>
        <p className="text-[48px] font-bold">Strategy-led</p>
        <p className="text-[48px] font-bold"><span style={{ color: '#0454A0' }}>design</span> in every</p>
        <p className="text-[48px] font-bold pb-10">detail</p>
        <p className="text-[#787878] text-[16px] pb-10">
        Founded in 1996, Building Technology System is specialising in providing innovative solutions for all things interior.

        </p>
        <button className="bg-gradient-to-b from-sky-700 to-blue-500 text-[16px] px-10 py-3 font-medium text-white">LEARN MORE</button>
      </div>
      </Slide>

      {/*RIGHT*/}
      <Slide bottom duration={1400}>
      <div className="right px-auto lg:px-0 w-full lg:w-1/2 flex justify-center items-center">
        <div>
        <div className="relative">
          <img src={ds_1} className="relative" />
          <div className="ml-[10px] font-Poppins absolute mt-[-130px] text-white text-left text-[25px]">
            <p>Jasraz Khan,</p>
            <p>Interior Designer</p>
          </div>
          <p className="absolute text-[16px] font-Poppins font-medium text-left mt-[5px] ml-[170px]">
            “If you will be coping someone,<br></br> you will never be the best”
          </p>
        </div>
        <div className="absolute mt-[-200px] ml-[-30px]">
          <img src={border} className="relative" />
          <img src={comma} className="absolute mt-[-20px] ml-[120px]" />
        </div>
        </div>
      </div>
      </Slide>
    </div>
  );
};

export default DesignStudioSecond;


