import career_bg from "../images/optimized/career-bg.jpg";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Navbar2 from "../components/Navbar2.js";
import career from "../images/optimized/career-image-man.jpg";
import Footer from "../components/Footer.js";

const Career = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_pnefe78";
    const templateId = "template_f215ocd";
    const publicKey = "IBaXG5ZZlqYp0F_ji";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "BTS",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("email sent successfully", response);
        setShowModal(true);
        setName("");

        setEmail("");

        setPhoneno("");
        setMessage("");
      })
      .catch((error) => {
        console.error("error sending email", error);
      });
  };
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${career_bg})`,
        }}
        className="bg-cover bg-center bg-no-repeat pb-40"
      >
        <div className="pt-[120px] flex flex-col justify-start items-start mx-auto ml-[95px]">
          <p className="text-left text-white text-[72px] font-Lora font-bold">
            Career
          </p>
        </div>
      </div>

      <div className="mb-10 mt-5 w-11/12 flex flex-col md:flex-row justify-center items-center gap-10 mx-auto">
        <div className="w-full lg:w-1/2">
          <div className="text-left flex flex-col gap-3 justify-start items-start mb-10">
            <p className="text-[50px] font-medium font-Playfair">
              Want to start your career with us?
            </p>
            <p className="uppercase text-[20px] font-Poppins text-[#0454A0]">
              Send your resume
            </p>
          </div>
          <div>
            <form onSubmit={handleSubmit} className="flex flex-col gap-7">
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                className="shadow-md border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Full Name"
                required
              />
              <input
                type="tel"
                name="telephone"
                value={phoneno}
                onChange={(e) => setPhoneno(e.target.value)}
                id="telephone"
                className="shadow-md border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Phone No."
                required
              />
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                className="shadow-md border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Email Address"
                required
              />
              <input className="" type="file" name="file" id="file" required />
              <textarea
                name="message"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
                className="shadow-md block p-4 w-full text-sm rounded-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Your Message Here..."
                required
              />
              <button
                onClick={() =>
                  document.getElementById("my_modal_3").showModal()
                }
                className={
                  "btn w-1/2 bg-gradient-to-b from-sky-700 to-blue-500 text-[16px] px-5 py-3 font-medium text-white hover:scale-110 transition-transform duration-300"
                }
              >
                Submit
              </button>
            </form>
          </div>
          {/*ALERT*/}

          <dialog id="my_modal_3" className="modal">
            <div className="modal-box">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                  ✕
                </button>
              </form>

              <p className="py-4 text-green-800">
                Thank you for reaching out to us!
              </p>
            </div>
          </dialog>
        </div>

        <div className="w-full lg:w-1/2">
          <img src={career} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Career;
