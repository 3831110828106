import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { useLocation, Link } from "react-router-dom";
import { Fade, Slide, Zoom, Rotate } from "react-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import vrf_image from "../../images/optimized/vrf/vrf-1.jpg";
import image2 from "../../images/optimized/mission-image-1.jpg";

import bg_image from "../../images/plain-black.jpg";
import demo from "../../images/bg_sketch.jpg";
import demo2 from "../../images/bts_design_3.jpg";
import huhu from "../../images/hero/bts_design_3.png";
import huhu2 from "../../images/huhu3.png";
import huhuhu from "../../images/hero/bts_design_4.png";
import img_1 from "../../images/hero/img1.jpg";
import img_2 from "../../images/hero/img2.jpg";
import img_3 from "../../images/hero/bts_design_1.jpg";
import img_4 from "../../images/hero/bts_design_2.jpg";
import img_5 from "../../images/hero/img5.jpg";
import img_6 from "../../images/hero/img6.jpg";
import Navbar2 from "../../components/Navbar2.js";

import "./Hero.css";

const Hero = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const calcParallax = (movement) => {
    return {
      x:
        ((mousePosition.x - window.innerWidth / 2) / window.innerWidth) *
        movement,
      y:
        ((mousePosition.y - window.innerHeight / 2) / window.innerHeight) *
        movement,
    };
  };

  const slideOneProps = useSpring({ ...calcParallax(-100) });
  const slideTwoProps = useSpring({ ...calcParallax(-70) });
  const slideThreeProps = useSpring({ ...calcParallax(-50) });
  const imgProps = useSpring({ ...calcParallax(-30) });
  const slideneProps = useSpring({ ...calcParallax(-120) });

  return (
    <div className="">
      <div
        id="container"
        onMouseMove={handleMouseMove}
        className="bg-white relative h-[100vh] w-[100%] pt-[60px] md:pt-[100px] mx-auto"
      >
        {/* <div className="flex flex-row w-11/12 mx-auto text-3xl justify-between text-black">
          <p>+</p>
          <p>+</p>
          <p>+</p>
        </div> */}
        {/* <div className="pt-10 z-10 pb-2 w-11/12 mx-auto text-lg text-black">
          
          <p className="z-10 text-left">BUILDING THE FURURE</p>
          <p className="z-10 text-left">BRIDGING INNOVATION AND QUALITY</p>
          
        </div> */}
        <div className="pt-10 z-10 w-11/12 mx-auto flex-col flex gap-5 md:gap-0 md:flex-row md:justify-between">
          <div className="z-10 flex flex-col items-start">
            <div className="text-black text-md z-10">
            BUILDING THE FURURE
            </div>

            <div className="text-black text-md z-10">
            BRIDGING INNOVATION AND QUALITY
            </div>
          </div>
          {/* <div className="text-black text-md md:text-xl text-left z-10">
            <p>ONE STOP BUILDING SOLUTION</p>
            <p>SINCE 1996</p>
          </div> */}
        </div>
        {/* <img src={bg_image} alt="" className="img absolute top-0 left-0 h-[100%] w-[100%]" /> */}

        <div className="w-11/12 pt-10 lg:pt-0 mx-auto text-black font-Lora font-medium flex flex-row justify-center items-center">
          {/* <div className="text-3xl">+</div> */}
          <div className="z-10">
            <p className="z-10 text-center text-[40px] md:text-[80px] font-semibold">
              ONE STOP
            </p>
            <p className="z-10 text-center text-[40px] md:text-[80px] font-semibold">
              BUILDING SOLUTION
            </p>
            <p className="z-10 text-center text-[40px] md:text-[80px] font-medium">
              SINCE <span className="italic">1996</span>
            </p>
          </div>
          {/* <div className="text-3xl">+</div> */}
        </div>

        <animated.img
          src={demo}
          style={{ ...slideThreeProps }}
          className="absolute opacity-35 w-[80%] h-[70%] lg:h-[100%] slide2 six left-[5%] top-[2%]"
        ></animated.img>

        <animated.img
          src={vrf_image}
          className="absolute z-2 slide2 five h-[150px] md:h-[250px] w-[150px] md:w-[250px] left-[2%] lg:left-[50%] top-[8%] md:top-[10%]"
          style={{ ...slideThreeProps }}
        ></animated.img>
        <animated.img
          src={demo2}
          className="absolute hidden lg:block z-4 slide2 five h-[200px] md:h-[300px] w-[200px] md:w-[300px] left-[10%] lg:left-[15%] top-[50%] md:top-[40%]"
          style={{ ...slideOneProps }}
        ></animated.img>
        <animated.img
          src={image2}
          className="absolute z-5 slide2 six h-[150px] md:h-[300px] w-[150px] md:w-[300px] left-[50%] lg:left-[63%] top-[45%] md:top-[25%]"
          style={{ ...slideThreeProps }}
        ></animated.img>

        {/* <animated.img
          src={img_2}
          className="absolute slide one h-[100px] md:h-[200px] w-[200px] md:w-[300px] z-4 left-[5%] md:left-[2%] top-[30%] md:top-[25%]"
          style={{ ...slideOneProps }}
        ></animated.img>
        <animated.img
          src={img_1}
          className="absolute slide two h-[100px] md:h-[200px] w-[200px] md:w-[300px] z-5 left-[2%] md:left-[4%] top-[23%] md:top-[18%]"
          style={{ ...slideTwoProps }}
        ></animated.img> */}
        {/* <animated.img
          src={img_3}
          className="absolute slide three h-[200px] w-[300px] z-3 left-[25%] top-[20%] hidden md:block"
          style={{ ...slideThreeProps }}
        ></animated.img>
        <animated.img
          src={img_4}
          className="absolute slide four h-[200px] w-[300px] z-3 left-[22%] top-[40%] hidden md:block"
          style={{ ...slideOneProps }}
        ></animated.img> */}

        {/* <animated.img
          src={huhuhu}
          className="absolute hidden lg:block slide2 z-5 h-[300px] w-[350px] six left-[5%] md:left-[58%] top-[45%] md:top-[25%]"
          style={{ ...slideneProps }}
        ></animated.img> */}

        {/* <div className="pt-20 pb-10 flex flex-row w-11/12 mx-auto text-3xl justify-between text-black">
          <p className="hover:scale-110 transition-transform duration-300 cursor-pointer">+</p>
          <p className="hover:scale-110 transition-transform duration-300 cursor-pointer">+</p>
          <p className="hover:scale-110 transition-transform duration-300 cursor-pointer">+</p>
        </div> */}

        <div className="pb-20 pt-[60px] md:pt-0 z-10 w-11/12 mx-auto flex-col flex gap-5 md:gap-0 md:flex-row md:justify-between">
          <div className="z-10 flex flex-col items-start">
            <Link to="mailto:buildingtechnologysystembd@gmail.com">
              <div className="text-black text-md z-10 hover:scale-110 transition-transform duration-300">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-black pt-1 pr-2"
                />
                buildingtechnologysystembd@gmail.com
              </div>
            </Link>
            <Link to="tel:+8801799250004">
              <div className="text-black text-md z-10 hover:scale-110 transition-transform duration-300">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="text-black pt-1 pr-2"
                />
                +8801988818050
              </div>
            </Link>
          </div>
          {/* <div className="text-black text-md md:text-xl text-left z-10">
            <p>ONE STOP BUILDING SOLUTION</p>
            <p>SINCE 1996</p>
          </div> */}
        </div>
      </div>

      
    </div>
  );
};

export default Hero;
