import Navbar from "../components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Fade, Slide, Zoom, Rotate } from "react-reveal";
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import line from "../images/line.png";
import line2 from "../images/line2.png";
import Counter from "../components/Counter";
import VideoSlider from "../components/VideoSlider";
import logo_video from "../video/all_logo_video.mp4";
import vrf_video from "../video/vrf_video.mp4";
import Hero from "./components/Hero";
import AgentSlider from "./components/AgentSlider";
import Mission from "./components/Mission";
import LogoSlider from "./components/LogoSlider";
import Products from "./components/Products";
import Values from "./components/Values";
import Form from "./components/Form";
import Footer from "../components/Footer";
import "./Home.css";

const Home = () => {
  return (
    <>
      {/* <div className="mt-5 w-11/12 flex flex-col lg:flex-row items-start justify-center lg:gap-5 mx-auto">
      <img src={line} className="hidden md:block absolute left-10"/>
        
        <div className="left w-full lg:w-1/2">
          <div className="social-icons flex flex-row gap-5 mb-5">
            <a href="#"><svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 50 50"
            >
              <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
            </svg>
            </a>
            <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 50 50"
            >
              <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
            </svg>
            </a>
            <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 50 50"
            >
              <path d="M 50.0625 10.4375 C 48.214844 11.257813 46.234375 11.808594 44.152344 12.058594 C 46.277344 10.785156 47.910156 8.769531 48.675781 6.371094 C 46.691406 7.546875 44.484375 8.402344 42.144531 8.863281 C 40.269531 6.863281 37.597656 5.617188 34.640625 5.617188 C 28.960938 5.617188 24.355469 10.21875 24.355469 15.898438 C 24.355469 16.703125 24.449219 17.488281 24.625 18.242188 C 16.078125 17.8125 8.503906 13.71875 3.429688 7.496094 C 2.542969 9.019531 2.039063 10.785156 2.039063 12.667969 C 2.039063 16.234375 3.851563 19.382813 6.613281 21.230469 C 4.925781 21.175781 3.339844 20.710938 1.953125 19.941406 C 1.953125 19.984375 1.953125 20.027344 1.953125 20.070313 C 1.953125 25.054688 5.5 29.207031 10.199219 30.15625 C 9.339844 30.390625 8.429688 30.515625 7.492188 30.515625 C 6.828125 30.515625 6.183594 30.453125 5.554688 30.328125 C 6.867188 34.410156 10.664063 37.390625 15.160156 37.472656 C 11.644531 40.230469 7.210938 41.871094 2.390625 41.871094 C 1.558594 41.871094 0.742188 41.824219 -0.0585938 41.726563 C 4.488281 44.648438 9.894531 46.347656 15.703125 46.347656 C 34.617188 46.347656 44.960938 30.679688 44.960938 17.09375 C 44.960938 16.648438 44.949219 16.199219 44.933594 15.761719 C 46.941406 14.3125 48.683594 12.5 50.0625 10.4375 Z"></path>
            </svg>
            </a>
          </div>
          <div className="flex flex-col justify-start items-start">
            <Slide left duration={1200}>
            <h1 className="animation text-left font-Lora text-6xl lg:text-7xl text-gray-800">
              Building the Future, Bridging Innovation and Quality.
            </h1>
            </Slide>
            <p className="text-neutral-400 font-Lora text-2xl text-left mt-5 mb-5">
              Welcome to building Technology System, your premier source for
              cutting-edge construction solutions.
            </p>
            <Link to="/Contact">
            <button type="button" className="mt-5 font-Poppins w-60 h-[60px] bg-gradient-to-b from-sky-700 to-blue-500 shadow text-white hover:scale-110 transition-transform duration-300">
              Get in Touch
              <FontAwesomeIcon
                icon={faArrowRight}
                className="pl-2 text-white"
              />
            </button>
            </Link>
            
            
            <Counter />
          </div>
        </div>
        <div className="right w-full flex flex-col lg:w-1/2">
          <VideoSlider />
          
        </div>
      </div> */}

      <Hero />

      <AgentSlider />

      <div className="pt-20">
        <div className="w-11/12 mx-auto flex flex-col lg:flex-row justify-center items-center">
          <Slide left duration={1200}>
            <div className="w-full md:w-1/2">
              <p className="text-stone-900 text-7xl text-left font-normal font-Lora leading-[72px] mb-5">
                One-Stop
                <br />
                Building Solution.
              </p>
            </div>
          </Slide>

          <Slide right duration={1200}>
            <div className="w-full md:w-1/2">
              <div className="group flex flex-row justify-center items-center text-left mb-3">
                <h4 className="p-5 w-1/2 border-l-4 border-l-neutral-400 group-hover:border-l-sky-700 text-sky-700 text-[28px] font-normal font-Poppins leading-loose">
                  Our Mission
                </h4>
                <h4 className="text-zinc-700 w-1/2 text-[20px] text-base font-normal font-Poppins leading-normal">
                  To empower with innovative solutions, exceptional service, and
                  sustainable practices.
                </h4>
              </div>

              <div className="group flex flex-row justify-center items-center text-left mb-3">
                <h4 className="p-5 w-1/2 border-l-4 border-l-neutral-400 group-hover:border-l-sky-700 text-sky-700 text-[28px] font-normal font-Poppins leading-loose">
                  Our Vision
                </h4>
                <h4 className="text-zinc-700 w-1/2 text-[20px] text-base font-normal font-Poppins leading-normal">
                  We aim to be recognised globally for our commitment to
                  quality, our transformative design solutions.
                </h4>
              </div>

              <div className="group flex flex-row justify-center items-center text-left mb-3">
                <h4 className="p-5 w-1/2 border-l-4 border-l-neutral-400 group-hover:border-l-sky-700 text-sky-700 text-[28px] font-normal font-Poppins leading-loose">
                  Our History
                </h4>
                <h4 className="text-zinc-700 w-1/2 text-[20px] text-base font-normal font-Poppins leading-normal">
                  Established in 1996, we have been a cornerstone allover
                  Bangladesh, evolving with the dynamic needs of the market.
                </h4>
              </div>
            </div>
          </Slide>
        </div>
      </div>
      <Slide left duration={1200}>
        <div className="mt-20 w-11/12 mx-auto flex flex-row gap-5 justify-center items-center">
          <video
            className="w-1/2"
            src={logo_video}
            autoPlay
            loop
            muted
            playsInline
          ></video>

          <video
            className="w-1/2"
            src={vrf_video}
            autoPlay
            loop
            muted
            playsInline
          ></video>
        </div>
      </Slide>

      <Values />
      <Form />
      <Footer />
      {/* <img src={rectangle} /> */}
    </>
  );
};

export default Home;
