import React from "react";
import carousel1 from "../../images/optimized/designStudio/carousel-1.jpg";
import carousel3 from "../../images/optimized/designStudio/carousel-3.jpg";
import carousel4 from "../../images/optimized/designStudio/carousel-4.jpg";
import arrow from "../../images/designStudio/arrow.png";
import "./DesignStudioForth.css";

export default function DesignStudioForth() {
  return (
    <div className="w-11/12 mx-auto mt-20 hidden md:block">
      <div className="w-10/12 mx-auto flex flex-row justify-end items-end gap-10 mb-10">
        <p className="text-[16px] text-[#0B5AA8] font-Poppins font-semibold">ALL</p>
        <p className="text-[16px] font-Poppins font-normal text-[#A1A1A1]">View all projects</p>
      </div>
      <div className="carousel w-full">

        {/*item 1*/}
        <div
          id="item1"
          className="carousel-item flex flex-row gap-3 justify-center items-center font-Poppins text-left text-white w-full"
        >
          <div className="relative-design">
            <img src={carousel1} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              Office<br></br>in<br></br>Baridhara
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                  Office<br></br>in<br></br>Baridhara
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>

          <div className="relative-design">
            <img src={carousel3} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              Gulshan-2<br></br>Working<br></br>Building
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                Gulshan-2<br></br>Working<br></br>Building
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>

          <div className="relative-design">
            <img src={carousel4} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              House<br></br>Near<br></br>Gulshan
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                House<br></br>Near<br></br>Gulshan
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>
        </div>

        {/*item2*/}
        <div
          id="item2"
          className="carousel-item flex flex-row gap-3 justify-center items-center font-Poppins text-left text-white w-full"
        >
          <div className="relative-design">
            <img src={carousel1} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              Office<br></br>in<br></br>Baridhara
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                  Office<br></br>in<br></br>Baridhara
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>

          <div className="relative-design">
            <img src={carousel3} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              Gulshan-2<br></br>Working<br></br>Building
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                Gulshan-2<br></br>Working<br></br>Building
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>

          <div className="relative-design">
            <img src={carousel4} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              House<br></br>Near<br></br>Gulshan
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                House<br></br>Near<br></br>Gulshan
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>
        </div>

        {/*item 3*/}
        <div
          id="item3"
          className="carousel-item flex flex-row gap-3 justify-center items-center font-Poppins text-left text-white w-full"
        >
          <div className="relative-design">
            <img src={carousel1} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              Office<br></br>in<br></br>Baridhara
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                  Office<br></br>in<br></br>Baridhara
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>

          <div className="relative-design">
            <img src={carousel3} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              Gulshan-2<br></br>Working<br></br>Building
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                Gulshan-2<br></br>Working<br></br>Building
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>

          <div className="relative-design">
            <img src={carousel4} className="w-full" />
            <p className="px-5 md:px-10 absolute mt-[-100px] md:mt-[-200px] font-normal text-[32px]">
              House<br></br>Near<br></br>Gulshan
            </p>
            <div className="px-5 lg:px-10 py-5 lg:py-10 text-white overlay-design">
              <div>
                <p className="text-[20px] lg:text-[32px] text-left font-Lora font-normal pb-2 lg:pb-7">
                House<br></br>Near<br></br>Gulshan
                </p>
                <p className="text-[13px] lg:text-[16px] text-left font-Poppins font-normal">
                  Nestled in the heart of Dhaka, Gulshan-2 stands as a testament
                  to dynamic growth and development. Here, amidst the bustling
                  streets and towering skyscrapers, the spirit of
                  entrepreneurship thrives.
                </p>
              </div>
              <div className="flex flex-row gap-5">
                <p className="text-[15px] lg:text-[20px] text-left font-bold font-Poppins">
                  See project
                </p>
                <img src={arrow} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full py-2 gap-2">
        <a href="#item1" className="btn btn-xs btn-circle"></a>
        <a href="#item2" className="btn btn-xs btn-circle"></a>
        <a href="#item3" className="btn btn-xs btn-circle"></a>
      </div>
    </div>
  );
}
