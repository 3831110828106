import React from "react";
import logo from "../images/light--blue.png";
import removebg from "../images/bts-remove-bg.png";

import footer_bg from "../images/optimized/footer-bg.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <div style={{ backgroundImage: `url(${footer_bg})` }}
    className="bg-cover">
      <div className="footer-content font-Poppins py-20 w-11/12 mx-auto flex flex-col lg:flex-row justify-center items-center gap-5 lg:gap-10">
        <div className="flex flex-col mr-auto justify-start items-start w-1/6 h-auto">
          <img src={logo} />
        </div>
        <div className="flex flex-col w-full lg:w-2/4 gap-2 justify-start items-start">
          <p className="text-white text-lg font-semibold">Contact Us</p>
          <Link to="https://maps.app.goo.gl/q4xS91avKuP91E668" target="_blank">
          <p className="text-white hover:text-blue-200 text-left text-lg">
            <FontAwesomeIcon icon={faLocation} className="pr-2 text-white hover:text-blue-200" />
            House-77/B , Block- F, Road-3, Banani, 1213 Dhaka, Bangladesh.
          </p>
          </Link>
          <Link to="mailto:buildingtechnologysystembd@gmail.com">
          <p className="text-white hover:text-blue-200 text-lg">
            <FontAwesomeIcon icon={faEnvelope} className="pr-2 text-white hover:text-blue-200" />
            buildingtechnologysystembd@gmail.com
          </p>
          </Link>
        </div>
        <div className="flex flex-col w-full lg:w-1/4 justify-start items-start gap-2">
          <Link to="/AuthorisedAgents"><p className="text-white hover:text-blue-200">Authorised Agents</p></Link>
          <Link to="/VRF"><p className="text-white hover:text-blue-200">VRF</p></Link>
          <Link to="/DesignStudio"><p className="text-white hover:text-blue-200">Design Studio</p></Link>
          <Link to="/Career"><p className="text-white hover:text-blue-200">Career</p></Link>
          <Link to="/Contact"><p className="text-white hover:text-blue-200">Contact Us</p></Link>
        </div>
        <div className="flex flex-col w-full lg:w-1/4 justify-start items-start gap-2 text-white">
          <p className="text-xl font-semibold">Leave us your email!</p>
          <p>We will reach out to you</p>
          <input
            type="email"
            id="email"
            name="email"
            className="text-base bg-white font-normal font-['Poppins'] py-1 w-4/5 px-2"
            placeholder="Email"
          />
          <button
            type="button"
            class="w-4/5 mb-3 px-2 py-1 bg-sky-700 text-center text-white font-['Poppins']"
            data-te-ripple-init
            data-te-ripple-color="light"
          >
            Submit
          </button>
          <p className="font-semibold">Follow Us</p>
          <div className="social-icons flex flex-row justify-start items-start gap-2">
            <div>
              <a href="#">
              <FontAwesomeIcon
                icon={faInstagram}
                className="text-white hover:text-blue-200"
              /></a>
            </div>
            <div>
              <a href="#">
              <FontAwesomeIcon
                icon={faFacebook}
                className="text-white hover:text-blue-200"
              /></a>
            </div>
            <div>
              <a href="#">
              <FontAwesomeIcon
                icon={faTwitter}
                className="text-white hover:text-blue-200"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
