import React from "react";
import bts_logo from "../images/logo-white.png";

const NavbarLogo = ({ onClick, link = false, src }) => {
  const inner = (
      <img
        alt="Logo"
        src={bts_logo}
        onClick={onClick}
        className="w-20 lg:w-40 h-auto cursor-pointer hover:scale-110 transition-transform duration-300"
      />
      
  );

  if (link) return <a href="/">{inner}</a>;
  return <div>{inner}</div>;
};

export default NavbarLogo;