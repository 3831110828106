import './App.css';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import Home from "./Home/Home.js";
import Navbar from "./components/Navbar.js";
import Navbar2 from"./components/Navbar2.js";
import AuthorisedAgents from './AuthorisedAgents/AuthorisedAgents.js';
import Blog from "./Blog/Blog.js";
import Contact from './Contact/Contact.js';
import Career from './Career/Career.js';
import DesignStudio from './DesignStudio/DesignStudio.js';
import FloatingButton from './components/FloatingButton.js';
import VRF from './VRF/VRF.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


library.add(fab);



function App() {
  const location = useLocation();

  // Check if current path is the home page
  const isHomePage = location.pathname === '/';
  return (
    <div className="App">
      {isHomePage ? <Navbar /> : <Navbar2 />}
      
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/AuthorisedAgents' element={<AuthorisedAgents />} />
        <Route path='/Blog' element={<Blog />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Career' element={<Career />} />
        <Route path='/DesignStudio' element={<DesignStudio />} />
        <Route path='/VRF' element={<VRF />} />
      </Routes>
      <FloatingButton />
      
    </div>
  );
}

export default App;
