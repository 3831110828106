import designStudio_bg from "../images/optimized/designStudio/designstudio-bg.jpg";
import DesignStudioSecond from "./components/DesignStudioSecond";
import taglineborder from "../images/designStudio/taglineborder.png";
import Navbar2 from "../components/Navbar2.js";
import DesignStudioThird from "./components/DesignStudioThird.js";
import DesignStudioForth from "./components/DesignStudioForth.js";
import Footer from "../components/Footer.js";
import DesignStudioForthSmall from "./components/DesignStudioForthSmall.js";
import { Fade, Slide, Zoom, Rotate } from "react-reveal";

const DesignStudio = () => {
  return (
    <div className="">
      <div
        style={{
          backgroundImage: `url(${designStudio_bg})`,
        }}
        className="bg-cover bg-center bg-no-repeat"
      >
        <Navbar2 />
        <div className="w-11/12 mx-auto">
        <div className="text-[72px] w-full mx-auto md:mx-0 md:w-1/2 flex justify-center items-center font-bold text-white font-Lora">
          <div className="w-[250px] h-[350px] flex flex-col justify-center items-center border-l-2 border-t-2 border-b-2 border-[#0B5AA8]">
            <p className="text-[#6AD7E5]">Design</p>
            <p>Studio</p>
          </div>
        </div>

        <Slide top duration={1400}>
        <div className="px-10 mt-5 lg:mt-0 flex flex-row gap-10 md:gap-[110px] font-Poppins justify-center items-center ml-auto w-full lg:w-3/5 h-[150px] bg-white">
          <div className="design-1 flex flex-row gap-3 justify-center items-center">
            <p className="text-[20px] md:text-[60px]">01</p>
            <p className="text-[16px] font-semibold">D Taj</p>
          </div>
          <div className="design-2 flex flex-row gap-3 justify-center items-center">
            <p className="text-[20px] md:text-[60px]">02</p>
            <p className="text-[16px] font-semibold">D Banani Heights</p>
          </div>
          <div className="design-3 flex flex-row gap-3 justify-center items-center">
            <p className="text-[20px] md:text-[60px]">03</p>
            <p className="text-[16px] font-semibold">D Tree</p>
          </div>
        </div>
        </Slide>
        </div>
      </div>
      <DesignStudioSecond />
      <DesignStudioThird />
      <DesignStudioForth />
      <DesignStudioForthSmall />
      <Footer />
    </div>
  );
};

export default DesignStudio;
