import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import HamburgerButton from "./Hamburger";
import bts_logo from "../images/logo.jpg";
import NavbarLogo from "./NavbarLogo";
import NavbarLogo2 from "./NavbarLogo2.js";
import bts_no_bg from "../images/white-logo.png";
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navbar = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Authorised Agents",
      path: "/AuthorisedAgents",
    },
    {
      name: "VRF",
      path: "/VRF",
    },
    
    
    {
      name: "Blog",
      path: "/Blog",
    },
    {
      name: "Career",
      path: "/Career",
    },
    {
      name: "Contact Us",
      path: "/Contact",
    },
  ];

  const handleClick = () => {
    setActive(false);
  };

  return (
    <div className="px-5 md:px-10 w-full h-40 absolute top-0 z-40">
      <div className="flex flex-row justify-between items-center">
        <div className="w-1/2" onClick={handleClick}>
          <NavbarLogo2 link />
        </div>

        <div className="flex flex-row font-Poppins justify-end w-1/2 gap-10 items-center">
          {location.pathname !== "/" && (
            <>
          <Link to="mailto:buildingtechnologysystembd@gmail.com">
            <div className="text-white text-xl hidden lg:flex hover:scale-110 transition-transform duration-300">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-white pt-1 pr-2"
              />
              buildingtechnologysystembd@gmail.com
            </div>
          </Link>
          <Link to="tel:+8801799250004">
            <div className="text-white text-xl hidden lg:flex hover:scale-110 transition-transform duration-300">
              <FontAwesomeIcon
                icon={faPhone}
                className="text-white pt-1 pr-2"
              />
              +8801988818050
            </div>
          </Link>
          </>
          )
          }
          <button
            id="nav-button"
            onClick={() => setMenuOpen(!menuOpen)}
            className={`text-2xl text-white font-bold ${
              menuOpen ? "active" : ""
            }`}
          >
            MENU
          </button>
        </div>
      </div>
      <div
        className={`mobile-menu sm:w-[100%] md:w-max m-auto h-full flex flex-col ${
          menuOpen ? "open" : ""
        }`}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <button
          active={menuOpen}
          onClick={() => setMenuOpen(!menuOpen)}
          className="btn btn-circle color-white bg-[#06549F] ml-auto mt-5 mr-5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {/* <img src={bts_no_bg} className="w-40 h-auto" /> */}
        <ul className="flex flex-col gap-5 mx-10 my-20">
          {navbar.map((el, i) => (
            <li
              key={i}
              className={`uppercase text-left text-2xl lg:text-3xl w-max ${
                location.pathname === el.path
                  ? "text-[#06549F] font-semibold cursor-pointer"
                  : "text-white hover:text-[#06549F] transition-transform duration-300 cursor-pointer"
              }`}
            >
              <Link to={el.path} onClick={handleClick} className="">
                {el.Icons}
                {el.name}
              </Link>
            </li>
          ))}
        </ul>

        <div className="social-icons flex flex-row gap-5 ml-10">
          <div>
            <a href="#">
              <FontAwesomeIcon
                icon={faInstagram}
                className="text-3xl text-[#06549F]"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <FontAwesomeIcon
                icon={faFacebook}
                className="text-3xl text-[#06549F]"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <FontAwesomeIcon
                icon={faTwitter}
                className="text-3xl text-[#06549F]"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
