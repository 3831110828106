import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

import { Fade, Slide, Zoom, Rotate } from "react-reveal";
import map from "../../images/map.png";

const ContactForm = ({ about }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_pnefe78";
    const templateId = "template_f215ocd";
    const publicKey = "IBaXG5ZZlqYp0F_ji";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "BTS",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("email sent successfully", response);
        setShowModal(true);
        setName("");
        setEmail("");
        setAddress("");
        setPhoneno("");
        setMessage("");
      })
      .catch((error) => {
        console.error("error sending email", error);
      });
  };
  return (
    <div className="flex flex-col gap-6 w-5/6  md:3/4 lg:w-1/2 mt-12 mx-auto">
      <Zoom duration={1200}>
        <p className="text-[55px] font-medium font-Playfair">Get in Touch!</p>
      </Zoom>

      <form
        onSubmit={handleSubmit}
        className="font-Poppins font-light flex flex-col gap-3"
      >
        <div className="flex gap-3">
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            className="shadow-md border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your Full Name"
            required
          />
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            className="shadow-md border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your Email Address"
            required
          />
        </div>
        <div className="flex">
          <input
            type="tel"
            name="telephone"
            value={phoneno}
            onChange={(e) => setPhoneno(e.target.value)}
            id="telephone"
            className="shadow-md border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your Phone No."
            required
          />

          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            name="address"
            id="address"
            className="shadow-md ml-4 border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your Address"
            required
          />
        </div>

        <div className="">
          <textarea
            name="message"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            className="shadow-md block p-4 w-full text-sm rounded-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Your Message Here..."
            required
          />
        </div>
        <button
        onClick={()=>document.getElementById('my_modal_3').showModal()}
          className={
            "btn w-1/2 bg-gradient-to-b from-sky-700 to-blue-500 text-[16px] px-5 py-3 font-medium text-white hover:scale-110 transition-transform duration-300"
          }
        >
          Submit
        </button>
      </form>

      {/*ALERT*/}

      <dialog id="my_modal_3" className="modal">
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          
          <p className="py-4 text-green-800">Thank you for reaching out to us!</p>
        </div>
      </dialog>

      <Link to="https://maps.app.goo.gl/dfE8L4amXERmUycN6" target="_blank">
        <img src={map} />
      </Link>
      {/* Modal */}
      {/* {showModal && (
        <div className="modal bg-slate-600">
          <div className="modal-content">
            <span className="close text-blue-500" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <p className="text-blue-500">Thank you for reaching out to me!</p>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ContactForm;
