import form_bg from "../../images/optimized/form-bg.jpg";
import { Fade, Slide, Zoom, Rotate } from 'react-reveal';
import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import dot from "../../images/dot.png";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_pnefe78";
    const templateId = "template_f215ocd";
    const publicKey = "IBaXG5ZZlqYp0F_ji";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "BTS",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("email sent successfully", response);
        setShowModal(true);
        setName("");
        
        setEmail("");

        setPhoneno("");
        setMessage("");
      })
      .catch((error) => {
        console.error("error sending email", error);
      });
  };
  return (
    <div
      style={{ backgroundImage: `url(${form_bg})` }}
      className="image opacity-100 bg-[#040423] bg-cover bg-no-repeat text-center mt-10"
    >
      <img src={dot} />
      <div className="flex w-11/12 mx-auto flex-col gap-10">
        <Zoom duration={1400}>
        <p className="mb-5 text-center text-white text-[30px] md:text-[57px] font-semibold font-Lora">
        Take The First Step Towards Transforming Your Space with Cutting-Edge Solutions
        </p>
        </Zoom>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col w-11/12 mx-auto lg:flex-row gap-4 mb-5 justify-center items-center">
            <div className="flex flex-col w-full lg:w-1/3">
              <label
                htmlFor="name"
                className="mb-3 text-white text-left font-semibold font-Poppins leading-normal"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
                className="text-white font-normal font-Poppins leading-normal border border-white bg-transparent h-14 p-4"
                placeholder="Your full name"
              />
            </div>

            <div className="flex flex-col w-full lg:w-1/3">
              <label
                htmlFor="email"
                className="mb-3 text-white text-left font-semibold font-Poppins leading-normal"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                className="text-white font-normal font-Poppins leading-normal border border-white bg-transparent h-14 p-4"
                placeholder="Your email address"
              />
            </div>

            <div className="flex flex-col w-full lg:w-1/3">
              <label
                htmlFor="phone"
                className="mb-3 text-white text-left font-semibold font-Poppins leading-normal"
              >
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                value={phoneno}
                onChange={(e) => setPhoneno(e.target.value)}
                name="phone"
                className="text-white font-normal font-Poppins leading-normal border border-white bg-transparent h-14 p-4"
                placeholder="Your phone number"
              />
            </div>
          </div>

          <div className="flex flex-row mb-5 justify-center items-center">
            <div className="flex flex-col w-11/12 mx-auto">
              <label
                htmlFor="message"
                className="mb-3 text-white text-left font-semibold font-Poppins leading-normal"
              >
                Message
              </label>
              <textarea
                type="message"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                className="text-white font-normal font-Poppins leading-normal border border-white bg-transparent h-48 p-4"
                placeholder="Your message"
              />
            </div>
          </div>

          <button
          onClick={()=>document.getElementById('my_modal_3').showModal()}
            type="button"
            className="btn w-11/12 mx-auto h-16 mb-5 px-8 py-5 bg-sky-700 text-center text-white text-base font-semibold font-Poppins leading-normal hover:text-black"
            
          >
            Send message
          </button>

        </form>
        <dialog id="my_modal_3" className="modal">
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          
          <p className="py-4 text-green-800">Thank you for reaching out to us!</p>
        </div>
      </dialog>
      </div>
      <img src={dot} className="ml-auto mr-[40px] mt-[-50px]" />
    </div>
  );
};

export default Form;
