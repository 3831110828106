import React, { useState, useEffect, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { hoverScale } from "./StyleStandardize";

const FloatingButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const buttonRef = useRef(null);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const buttonStyle =
    "bg-[#0B5AA8] text-white p-4 cursor-pointer flex flex-row gap-1 rounded-full";

  return (
    <div className="fixed bottom-5 right-5 flex flex-col items-end z-50">
      {/* Circle button */}
      <div
        ref={buttonRef}
        className={`rounded-full bg-[#0B5AA8] text-white p-4 text-xs cursor-pointer relative ${
          isExpanded === true && "animate-spin"
        } `}
        onClick={toggleExpansion}
      >
        <MenuIcon />
      </div>

      {/* Expanded buttons */}
      <div
        className={`${
          isExpanded ? "flex flex-col gap-2 absolute w-max" : "hidden"
        } rounded-md`}
        style={{
          transform: `translateY(${isExpanded ? "-105%" : "0"})`,
        }}
      >
        <div
          className={`${buttonStyle} ${hoverScale}`}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <LinkedInIcon />
        </div>
        <div
          className={`${buttonStyle} ${hoverScale}`}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <FacebookIcon />
        </div>
        <div
          className={`${buttonStyle} ${hoverScale}`}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <InstagramIcon />
        </div>
        <div
          className={`${buttonStyle} ${hoverScale}`}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <WhatsAppIcon />
        </div>
      </div>
    </div>
  );
};

export default FloatingButton;
