import React from 'react';
import blog_bg from "../images/optimized/contact-bg.jpg";
import coming from "../images/comingg.webp";
import Footer from '../components/Footer.js';

export default function Blog() {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${blog_bg})`,
        }}
        className="bg-cover bg-center bg-no-repeat pb-20"
      >
        
        <div className="pt-[120px] flex flex-col justify-start items-start mx-auto ml-[95px]">
          <p className="text-left text-white text-[72px] font-Lora font-bold">
            Blog
          </p>
        </div>
      </div>
        <div className='flex justify-center items-center'>
      <img src={coming} />
      </div>
        <Footer />
    </div>
  )
}
