import vrf_1 from "../../images/optimized/vrf/vrf-1.jpg";
import vrf_2 from "../../images/optimized/vrf/vrf-2.jpg";
import vrf_3 from "../../images/optimized/vrf/vrf-3.jpg";
import vrf_4 from "../../images/optimized/vrf/vrf-4.jpg";
import { Fade, Slide, Zoom, Rotate } from "react-reveal";

const VRFSecondSection = () => {

    const vrfArray = [
        {
            img: vrf_1,
            title: "CONSULTATION",
            description: "Our VRF consultation services begin with a comprehensive assessment of your building's heating and cooling needs. We work closely with you to understand your requirements, budget constraints, and long-term goals to tailor a VRF system that meets your specific needs."
        },

        {
            img: vrf_2,
            title: "INSTALLATION & REPAIR",
            description: "We stand behind the quality of our workmanship and use only genuine parts and components for VRF repairs. Our goal is to deliver lasting solutions that enhance the reliability, efficiency, and longevity of your HVAC system. We follow industry best practices and manufacturer guidelines to deliver high-quality installations that meet or exceed your expectations."
        },

        {
            img: vrf_3,
            title: "MAINTENANCE",
            description: "Regular maintenance is essential for maximizing the performance and lifespan of your VRF system. Our maintenance plans include scheduled inspections to identify potential issues early, prevent breakdowns, and optimize system efficiency."
        },

        {
            img: vrf_4,
            title: "CLEANING",
            description: "We offer comprehensive system decontamination services to remove microbial growth, odors, and other contaminants from VRF components. Our thorough cleaning protocols improve system performance, indoor air quality, and occupant comfort."
        }
    ]
    return ( 
        <div className="mb-10 w-11/12 mx-auto mt-[500px] lg:mt-60 flex flex-col justify-center items-center">
            {vrfArray.map((el,i) => {
                return(
                    <div className={`w-11/12 mx-auto flex ${
                        i % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
                      } justify-center flex-col items-center`}>
                        <Fade right={i % 2 !== 0} left={i % 2 === 0}>
                        <div className="w-full lg:w-1/2"><img src={el.img} className="w-[100%] h-[469px]" /></div>
                        </Fade>
                        <Fade left={i % 2 !== 0} right={i % 2 === 0}>
                        <div className="bg-[#0B5AA8] w-full lg:w-1/2 h-[469px] p-10 text-left flex flex-col justify-center items-start text-white">
                            <p className="pb-3 text-[34px] font-bold font-Playfair">{el.title}</p>
                            <p className="text-[17px] font-Poppins">{el.description}</p>
                        </div>
                        </Fade>

                    </div>
                );
            })}
        </div>
     );
}
 
export default VRFSecondSection;