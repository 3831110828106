import video_design from "../../video/interior-2.mp4";
import { Fade, Slide, Zoom, Rotate } from "react-reveal";

const DesignStudioThird = () => {
  return (
    <div className="mt-40 mx-auto w-11/12 flex flex-col lg:flex-row justify-center items-center gap-5">
      <div className="w-full lg:w-1/2 flex justify-center items-center">
      <video
          className="h-[500px]"
          src={video_design}
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </div>
      
      <Slide right duration={1400}>
      <div className="w-full lg:w-1/2 text-left font-Poppins flex flex-col justify-center items-start">
        <p className="text-[#0454A0] text-[14px] pb-5 font-medium">SERVICES</p>
        <div className="text-[48px] font-bold mb-5">
            <p>Thought out</p>
            <p>to the smallest</p>
            <p>detail</p>
        </div>
        <div className="text[16px] uppercase font-normal">
            <p className="pb-3">01. Space Planning</p>
            <p className="pb-3">02. Concept Development</p>
            <p className="pb-3">03. Color Consultation</p>
            <p className="pb-3">04. Furniture Selection and Layout</p>
            <p className="pb-3">05. Material and Finish Selection</p>
        </div>
      </div>
      </Slide>
    </div>
  );
};

export default DesignStudioThird;
