import { Fade, Slide, Zoom, Rotate } from 'react-reveal';
import value1 from "../../images/value-1-icon.png";
import value2 from "../../images/value-2-icon.png";
import value3 from "../../images/value-3-icon.png";
import value4 from "../../images/value-4-icon.png";
import value5 from "../../images/value-5-icon.png";
import value6 from "../../images/value-6-icon.png";
import value7 from "../../images/value-7-icon.png";
import value8 from "../../images/value-8-icon.png";
import dot from "../../images/dot.png";
import "./Values.css";

const Values = () => {
    return ( 
        <div className="mt-20 w-11/12 mx-auto">
            
            <Zoom duartion={1400}><p className="mb-5 text-center text-stone-900 text-7xl font-normal font-Lora leading-[72px]">Our Values</p></Zoom>
            <p className="mb-10 text-center text-zinc-700 text-xl font-['Poppins']">Ensuring that every interaction and endeavor reflects our commitment to excellence and positive impact.</p>
            
            <div className="flex flex-row items-center justify-center flex-wrap gap-2">
            
                {/*CARD-1*/}
                <Slide left duration={1200}>
                <div className="plain felx flex-col justify-center items-start text-left w-[310px] h-[278px] px-8 py-6 bg-zinc-100 hover:bg-[#040423]">
                    <img src={value1} alt="value-1" />
                    <p className="text-stone-900 text-[21px] font-normal font-['Poppins'] leading-normal">Forward-Thinking Solutions</p>
                    <p className="text-zinc-700 text-base font-normal font-['Arial'] leading-normal">We embrace innovation as the cornerstone of our approach, constantly seeking and implementing progressive solutions that redefine industry standards.</p>
                </div>
                </Slide>

                {/*CARD-2*/}
                <Slide top duration={1200}>
                <div className="dark felx flex-col justify-center items-start text-left w-[310px] h-[278px] px-8 py-6 bg-[#040423] hover:bg-zinc-100">
                    <img src={value2} alt="value-2" />
                    <p className="text-white text-[21px] font-normal font-['Poppins'] leading-normal">Customer Success First</p>
                    <p className="text-white text-base font-normal font-['Arial'] leading-normal">Putting our clients at the forefront, we prioritize understanding their needs and delivering tailored solutions.</p>
                </div>
                </Slide>

                {/*CARD-3*/}
                <Slide top duration={1200}>
                <div className="mild1 felx flex-col justify-center items-start text-left w-[310px] h-[278px] px-8 py-6 bg-[#06549F] hover:bg-zinc-100">
                    <img src={value3} alt="value-3" />
                    <p className="text-white text-[21px] font-normal font-['Poppins'] leading-normal">Sustainable Solutions</p>
                    <p className="text-white text-base font-normal font-['Arial'] leading-normal">Recognizing our responsibility to the environment, we are dedicated to offering sustainable products and services.</p>
                </div>
                </Slide>

                {/*CARD-4*/}
                <Slide right duration={1200}>
                <div className="plain felx flex-col justify-center items-start text-left w-[310px] h-[278px] px-8 py-6 bg-zinc-100 hover:bg-[#040423]">
                    <img src={value4} alt="value-4" />
                    <p className="text-stone-900 text-[21px] font-normal font-['Poppins'] leading-normal">Teamwork and Partnership</p>
                    <p className="text-zinc-700 text-base font-normal font-['Arial'] leading-normal">By working closely with our clients, partners, and each other, we foster a culture that thrives on shared goals and mutual success.</p>
                </div>
                </Slide>

                {/*CARD-5*/}
                <Slide left duration={1200}>
                <div className="plain felx flex-col justify-center items-start text-left w-[310px] h-[278px] px-8 py-6 bg-zinc-100 hover:bg-[#040423]">
                    <img src={value5} alt="value-5" />
                    <p className="text-stone-900 text-[21px] font-normal font-['Poppins'] leading-normal">Uncompromising Quality</p>
                    <p className="text-zinc-700 text-base font-normal font-['Arial'] leading-normal">We adhere to the highest standards to ensure the durability and reliability of every product and service we provide.</p>
                </div>
                </Slide>

                {/*CARD-6*/}
                <Slide bottom duration={1200}>
                <div className="mild2 felx flex-col justify-center items-start text-left w-[310px] h-[278px] px-8 py-6 bg-[#06549F] hover:bg-[#040423]">
                    <img src={value6} alt="value-6" />
                    <p className="text-white text-[21px] font-normal font-['Poppins'] leading-normal">Honesty and Transparency</p>
                    <p className="text-white text-base font-normal font-['Arial'] leading-normal">We conduct business with the utmost integrity, fostering trust through transparent communication, ethical practices, and a steadfast commitment to our promises.</p>
                </div>
                </Slide>

                {/*CARD-7*/}
                <Slide bottom duration={1200}>
                <div className="dark felx flex-col justify-center items-start text-left w-[310px] h-[278px] px-8 py-6 bg-[#040423] hover:bg-zinc-100">
                    <img src={value7} alt="value-7" />
                    <p className="text-white text-[21px] font-normal font-['Poppins'] leading-normal">Learning and Growing</p>
                    <p className="text-white text-base font-normal font-['Arial'] leading-normal">Embracing a culture of continuous improvement, we are committed to learning, adapting, and evolving. </p>
                </div>
                </Slide>

                {/*CARD-8*/}
                <Slide right duration={1200}>
                <div className="plain felx flex-col justify-center items-start text-left w-[310px] h-[278px] px-8 py-6 bg-zinc-100 hover:bg-[#040423]">
                    <img src={value8} alt="value-8" />
                    <p className="text-stone-900 text-[21px] font-normal font-['Poppins'] leading-normal">Contributing to Communities</p>
                    <p className="text-zinc-700 text-base font-normal font-['Arial'] leading-normal">We believe in making a positive impact through initiatives that support local growth and well-being.</p>
                </div>
                </Slide>
            </div>
        </div>
     );
}
 
export default Values;