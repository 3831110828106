import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import asahi_logo from "../../images/optimized/logos/removedbg/1.png";
import alpolic_logo from "../../images/optimized/logos/removedbg/2.png";
import bond_logo from "../../images/optimized/logos/removedbg/3.png";
import durlum_logo from "../../images/optimized/logos/removedbg/4.png";
import ge_logo from "../../images/optimized/logos/removedbg/5.png";
import asahi from "../../images/slider/asahi.jpg";
import alpolic from "../../images/slider/alpolic.jpg";
import bond from "../../images/slider/bond.jpg";
import durlum from "../../images/slider/durlum.jpg";
import ge from "../../images/slider/ge.jpg";
import arrow from "../../images/designStudio/arrow.png";
import line3 from "../../images/line3.png";
import { Link } from "react-router-dom";
import "./AgentSlider.css";

const AgentSlider = () => {
  const [textIndex, setTextIndex] = useState(0);
  const texts = ["Asahi", "Alpolic", "BOND", "DURLUM", "GE"];

  const texts2 = ["India", "Metal", "Sealant", "", "Silicons"];

  const texts3 = ["Glass Ltd.", "Composite.", "USA.", "", ""];

  const logo_images = [
    asahi_logo,
    alpolic_logo,
    bond_logo,
    durlum_logo,
    ge_logo,
  ];

  const links = [
    "https://www.aisglass.com/",
    "https://www.alpolic-americas.com/",
    "bondsealant.com",
    "https://www.durlum.com/en/",
    "https://siliconeforbuilding.com/",
  ];

  const textVariants = {
    hidden: { opacity: 0, y: "-100%" },
    visible: { opacity: 1, y: "0%" },
    exit: { opacity: 0, y: "100%" },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [asahi, alpolic, bond, durlum, ge];
  const backgroundStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 1s ease-in-out",
    animation: "zoom-in-zoom-out 4s ease infinite",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000);

    return () => clearInterval(interval);
  }, [texts.length, images.length]);

  const handleNextClick = () => {
    setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevClick = () => {
    setTextIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      <div
        className="h-[600px] w-4/5 mt-16 md:mt-28 mx-auto text-white relative rounded-xl"
        style={backgroundStyle}
      >
        <div className="absolute top-[5%] lg:top-[25%] left-10 md:left-[25%] flex flex-col-reverse lg:flex-row justify-between items-center gap-5 lg:gap-[200px] text-white text-left">
          <motion.div
            key={textIndex}
            initial="hidden"
            animate="visible"
            className="pl-5 pt-5 pb-5 border-l-2 border-t-2 border-b-2 border-[#0B5AA8]"
            exit="exit"
            variants={textVariants}
            transition={{ duration: 1 }}
          >
            {/* <img className="w-5" src={logo_images[textIndex]} /> */}
            <p className="font-semibold font-Lora pb-5">
              {/* <div className="w-7 h-[2px] bg-white my-auto" /> */}
              AUTHORISED AGENTS
            </p>
            <h1 className="font-Poppins text-3xl md:text-4xl font-normal md:leading-normal animate-fadeInOut transition delay-150 duration-300 ease-in-out">
              {texts[textIndex]}
            </h1>
            <h1 className="text-[#F11721] font-Poppins text-3xl md:text-4xl font-normal md:leading-normal animate-fadeInOut transition delay-150 duration-300 ease-in-out">
              {texts2[textIndex]}
            </h1>
            <h1 className="font-Poppins text-3xl md:text-4xl font-normal md:leading-normal animate-fadeInOut transition delay-150 duration-300 ease-in-out">
              {texts3[textIndex]}
            </h1>
            <div className="flex flex-row gap-2 pt-10">
              <Link to={links[textIndex]} target="_blank">
                <p>View More</p>
              </Link>

              <img src={arrow} />
            </div>
          </motion.div>

          <motion.img
            initial="hidden"
            animate="visible"
            variants={textVariants}
            exit="exit"
            transition={{ duration: 1 }}
            className="w-[300px]"
            src={logo_images[textIndex]}
          />
        </div>
        <div className="absolute left-2 md:left-[20%] bottom-8 flex flex-row flex-wrap gap-3 justify-between w-11/12">
          <div className="flex flex-row gap-5">
            <button className="text-white" onClick={handlePrevClick}>
              PREV
            </button>
            <img src={line3} />
            <button className="text-white" onClick={handleNextClick}>
              NEXT
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentSlider;
